import React from 'react'
import styled from "styled-components";


const Load = styled.div`
  background-color: #083142; 
  width: 100%; 
  text-align: center; 
  height: calc( 100vh );
  @media screen and (max-width: 820px) {
    padding-top: 30%;
  }
`;

function Loader() {

  return (
    <Load>
     <img src="/images/loader1.webp" alt="" width="500px" style={{marginTop: "16%"}}/>
    </Load>
    
  )
}

export default Loader
