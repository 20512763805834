import React from 'react'
// import HeroSection from '../../HeroSection'
// import Pricing from '../../Pricing'
// import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour } from './Data'
import Header from './components/Header'
import Approach from './components/Approach'

function Home() {
  return (
    <>
      <Header />
      <Approach />
      {/* <HeroSection {...homeObjOne} />
      <HeroSection {...homeObjThree} />
      <HeroSection {...homeObjTwo} />
      <Pricing />
      <HeroSection {...homeObjFour} /> */}
    </>
  )
}

export default Home
