import React from 'react'
import './Animation.css'

function Animation() {

  return (
    <div className="curtain-container">
		<div className="curtain"></div>
	</div>
  )
}

export default Animation
