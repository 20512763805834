import React from 'react';
import "../header.css";

function Approach() {
  return (
    <div id="Approach">
      <div className='approach_content container'>
        <div className='approach_include'>
          <div className='partnering'>
            <div className='partner-title'>
              Growth equity for the Texas region.
            </div>
            <div className='partner-details'>
              <p>
                We operate and invest in capital-efficient businesses in a variety of industries.  We seek out attractive opportunities in industries which are often underserved relative to their venture and growth capital needs.
              </p>
            </div>
          </div>
          <div className='intro-criteria-container'>
            <div className="criteria-heading">Investment Criteria</div>
            <div className='row align-center parent_container'>
              <div className="col-lg-3 col-md-6 image-item stat cell small-6 medium-4 large-2 item-col">
                <div className='grid-x gird-container'>
                  <div className='image-container'>
                    <img className='img-grp' src="/images/image03.webp" alt=''/>
                  </div>
                  <div className='image-title'>Based in Texas or Surrounding Regions</div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 image-item stat cell small-6 medium-4 large-2 item-col">
                <div className='grid-x gird-container'>
                  <div className='image-container'>
                    <img className='img-grp' src='/images/bulb03.webp' alt=''/>
                  </div>
                  <div className='image-title'>Founder-Led</div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 image-item stat cell small-6 medium-4 large-2 item-col">
                <div className='grid-x gird-container'>
                  <div className='image-container'>
                    <img className='img-grp' src='../images/icon-non-bay-light03.webp' alt=''/>
                  </div>
                  <div className='image-title'>Non-SaaS</div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 image-item stat cell small-6 medium-4 large-2 item-col">
                <div className='grid-x gird-container'>
                  <div className='image-container'>
                    <img className='img-grp' src='../images/icon-equity-light03.webp' alt=''/>
                  </div>
                  <div className='image-title'>Cash Flow Positive</div>
                </div>
              </div>

            </div>
          </div>
          <div className='intro-criteria-container'>
            <div className="criteria-heading">Current Industries</div>
            <div className='row align-center parent_container'>
              
              <div className="col-lg-3 col-md-6 image-item stat cell small-6 medium-4 large-2 item-col">
                <div className='grid-x gird-container'>
                  <div className='image-container'>
                    <img className='img-grp' src='../images/boat02.webp' alt=''/>
                  </div>
                  <div className='image-title'>Gaming and Lodging</div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 image-item stat cell small-6 medium-4 large-2 item-col">
                <div className='grid-x gird-container'>
                  <div className='image-container'>
                    <img className='img-grp' src='../images/oil03.webp' alt=''/>
                  </div>
                  <div className='image-title'>Oil and Gas</div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 image-item stat cell small-6 medium-4 large-2 item-col">
                <div className='grid-x gird-container'>
                  <div className='image-container'>
                    <img className='img-grp' src="/images/insurance03.webp" alt=''/>
                  </div>
                  <div className='image-title'>Insurance</div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 image-item stat cell small-6 medium-4 large-2 item-col">
                <div className='grid-x gird-container'>
                  <div className='image-container'>
                    <img className='img-grp' src='/images/estate03.webp' alt=''/>
                  </div>
                  <div className='image-title'>Real Estate</div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Approach;
