import React from 'react'
import './Footer.css'

function Footer() {
  return (
    <div id="footer-inv" className='footer-container'>
      <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <div className="logo-footer">  
              <a href="/" rel="home">
                <img className='logo1' src="/images/logo2.png" alt=''/>
              </a>
            </div>
            <h5 style={{color: "black", width: "100%", textAlign: "center", margin: "30% 0", fontFamily: "Libre Baskerville",fontWeight: "bold", border: "1px dashed rgb(59, 60, 78) outline: none"}}>info@hilyardcapital.com</h5>
          </div>
        </div>
      </div>
      <section className='social-media'>
        <div className='social-media-wrap' style={{ display: "flex", justifyContent: "center", margin: "auto"}}>
          <div className="legal"> ©Copyright 2022. All Rights Reserved.&nbsp;
          </div>
          <div className="second-line" style={{ fontFamily: "Libre Baskerville"}}> 
            <a href="/privacy" style={{fontFamily: "Libre Baskerville"}}>Privacy</a> and <a href="/terms" style={{fontFamily: "Libre Baskerville"}}>Terms</a>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Footer
