import React from 'react'
import './Privacy.css'

function Privacy() {
  return (
    <div id="Privacy" className='privacy'>
        <div className='topics'>
              Privacy
      </div>
      <div className='context'>
        <div className='context-title'>Preamble</div>
        <div className='context-text'>
            <p>
                This privacy policy sets out how Hilyard Capital uses and protects any information that you give when you use this website. Hilyard Capital is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, then you can be assured that it will only be used in accordance with this privacy statement. Hilyard Capital may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are satisfied with any changes.
            </p>
            </div>
      </div>
      <div className='context'>
        <div className='context-title'>What We Collect</div>
        <div className='context-text'>
            <p>We collect information from you when you register on our website, subscribe to our newsletter, respond to a survey or fill out a form.</p>
            <p>When ordering or registering on our site, as appropriate, you may be asked to enter your: name, e-mail address, mailing address, and phone number. You may, however, visit our site anonymously.</p>
        </div>
      </div>
      <div className='context'>
        <div className='context-title'>What We Do With The Information We Gather</div>
        <div className='context-text'>
            <p>Your information, whether public or private, will not be sold, exchanged, transferred, or given to any other company for any reason whatsoever, without your consent, other than for the express purpose of delivering the purchased product or service requested. We require this information to understand your needs and provide you with a better service, and in particular for the following reasons. Any of the information we collect from you may be used in one of the following ways:</p>
            
            <ul>
                <li>To personalize your experience with our services and our website (your information helps us to better respond to your individual needs).</li>
                <li>To improve our website (we continually strive to improve our website offerings based on the information and feedback we receive from you).</li>
                <li>To improve customer service (your information helps us to more effectively respond to your customer service requests and support needs).</li>
                <li>To administer a contest, promotion, survey or other site feature.</li>
                <li>To send periodic emails. The email address you provide for order processing, may be used to send you information and updates pertaining to your interests and/or services in which you are currently enrolled, in addition to receiving occasional company news, updates, related product or service information, etc.</li>
            </ul>
            
            <p>Note: If at any time you would like to unsubscribe from receiving future emails, we include detailed unsubscribe instructions at the bottom of each email.</p>
        </div>
      </div>
      <div className='context'>
        <div className='context-title'>Security</div>
        <div className='context-text'>
            <p>
                We are committed to ensuring that your information is secure. In order to prevent unauthorized access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.
            </p>
        </div>
      </div>
      <div className='context'>
        <div className='context-title'>How We Use Cookies</div>
        <div className='context-text'>
            <p>A cookie is a small file which asks permission to be placed on your computer’s hard drive. Once you agree, the file is added and the cookie helps analyze web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences.</p>
            <p>We use traffic log cookies to identify which pages are being used. This helps us analyze data about web page traffic and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes.</p>
            <p>Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us. You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website in the future.</p>
            <p>As you browse https://long-ridge.com, advertising cookies will be placed on your computer so that we can understand what you are interested in. Our display advertising partners then enables us to present you with retargeting advertising (also known as remarketing) on other sites based on your previous interaction with https://long-ridge.com. The techniques our partners employ do not collect personal information such as your name, email address, postal address or telephone number.</p>
            <p>If you would like to opt out of Google’s use of interest-based advertising and remarketing cookies, please visit Google’s Ad Settings page.</p>
            <p>In addition, you may also opt out of third-party vendor’s internet-based advertising cookies by visiting this page.</p>
        </div>
      </div>
      <div className='context'>
        <div className='context-title'>Links To Other Websites</div>
        <div className='context-text'>
            <p>
                Occasionally, at our discretion, we may include or offer third party products or services on our website. These third party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.
                </p>
            </div>
      </div>
      <div className='context'>
        <div className='context-title'>Controlling Your Personal Information</div>
        <div className='context-text'>
            <p>You may choose to restrict the collection or use of your personal information in the following ways:</p>
            <p>If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by writing to or emailing us.</p>
            <p>We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so. We may use your personal information to send you promotional information about third parties which we think you may find interesting if you tell us that you wish this to happen.</p>
            <p>You may request details of personal information which we hold about you under the Data Protection Act 1998. A small fee will be payable. If you would like a copy of the information held on you please write to us using the contact information listed below.</p>
            <p>If you believe that any information we are holding on you is incorrect or incomplete, please write to or email us as soon as possible, at the above address. We will promptly correct any information found to be incorrect.</p>
        </div>
      </div>
      <div className='context'>
        <div className='context-title'>Changes To Our Privacy Policy</div>
        <div className='context-text'>
            <p>If we decide to change our privacy policy, we will post those changes on this page, send an email notifying you of any changes, and/or update the Privacy Policy modification date below.</p>
            <p>This policy was last modified on January 22, 2019</p>
        </div>
      </div>
      <div className='context'>
        <div className='context-title'>Contacting Us</div>
        <div className='context-text'>
            <p>
                If there are any questions regarding this privacy policy you may contact us using the information below.
            </p>
        </div>
      </div>
      <div className='context'>
        <div className='context-title'>Hilyard Capital</div>
            <div className='context-text'>
                <p>info@hilyardcapital.com</p>
            </div>
      </div>
      
    </div>
  )
}

export default Privacy
