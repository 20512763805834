import React from 'react';
import Particle from 'react-particles-js';
import { useBreakpoints } from "react-responsive-hooks";
import "../header.css";
import "./Effect";

function Header() {
   const { isXS, isSM } = useBreakpoints();
   return (
      <div id="Header">
         {
            !isXS && !isSM ? (
               <div style={{ position: 'absolute', zIndex: "999"}}>
                  <Particle
                     height="100vh" 
                     params={{
                        "particles": {
                           "number": {
                                 "value": 50
                           },
                           "size": {
                                 "value": 3
                           },
                        },
                        
                        "interactivity": {
                           "events": {
                                 "onhover": {
                                    "enable": true,
                                    "mode": "repulse"
                                 }
                           }
                        }
                     }} />
               </div>
            ) : (
               <div style={{ position: 'absolute', zIndex: "999"}}>
                  <Particle
                     height="100vh" 
                     params={{
                        "particles": {
                           "number": {
                                 "value": 20
                           },
                           "size": {
                                 "value": 3
                           },
                        },
                        
                        "interactivity": {
                           "events": {
                                 "onhover": {
                                    "enable": true,
                                    "mode": "repulse"
                                 }
                           }
                        }
                     }} />
               </div>
            )
         }
         
         
         <div className="logo">  
              <a href="/" rel="home">
                <img className='logo' src="/images/logo1.png" width="70px" height="70px" alt=''/>
              </a>
            </div>
         <div className='header-text'>
         <img className='header-logo' src="/images/logo2.png" alt=''/>
            <div className='header-content'>
               Capital by founders, for founders
            </div>
         </div>
      </div>
   )
}

export default Header;
