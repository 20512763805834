import React, {useState, useEffect} from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import './App.css'
import Home from './components/pages/HomePage/Home'
import Services from './components/pages/Services/Services'
import Privacy from './components/pages/Privacy/Privacy'
import Terms from './components/pages/Terms/Terms'
import Footer from './components/pages/Footer/Footer'
import Products from './components/pages/Products/Products'
import SignUp from './components/pages/SignUp/SignUp'
import Loader from './components/pages/Loader'
import Animation from './components/pages/Animation'

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [isRun, setRun] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 1500)
    setTimeout(() => {
      setRun(false)
    }, 1600)
  }, []);
  if (isLoading) 
    return (
      <>
      <Loader /> 
      </>
    )
  if (isRun) 
    return (
      <>
      <Animation/>
      </>
    )
  return (
    <>
    <Router>
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/privacy' component={Privacy} />
        <Route path='/terms' component={Terms} />
        <Route path='/services' component={Services} />
        <Route path='/products' component={Products} />
        <Route path='/sign-up' component={SignUp} />
      </Switch>
      <Footer />
    </Router>
    </>
  )
}

export default App
